/* You can add global styles to this file, and also import other style files */
body, h2{
    padding: 0;
    margin: 0;
}
html, body { height: 100vh; overflow: hidden; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
.mat-typography h2{
    margin-bottom: 0;
}

